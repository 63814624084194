import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./shared.pages/login/Login";
import Signup from "./shared.pages/signup/Signup";
import ScrollToTop from "./utils/ScrollToTop";
import Home from "./user/pages/home/Home";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
