import "./Signup.css";
import logo from "../../assets/logo.png";
import google__icon from "../../assets/google.png";
import facebook__icon from "../../assets/facebook.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
function Signup() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      email,
      password,
      firstName,
      lastName,
    });
  };
  return (
    <div className="signup">
      <div className="signup__container">
        <img src={logo} alt="" />
        <h1>Signup</h1>
        <div className="signup__container__social__signin">
          <button>
            <img src={google__icon} alt="" />
            Sign in with Google
          </button>
          <button>
            <img src={facebook__icon} alt="" />
            Sign in with Facebook
          </button>
        </div>

        <form className="signup__container__form" onSubmit={handleSubmit}>
          <input
            type="text"
            important
            placeholder="Enter First Name"
            onClick={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <input
            type="password"
            placeholder="Enter Last Name"
            onClick={(e) => {
              setLastName(e.target.value);
            }}
          />
          <input
            type="email"
            important
            placeholder="Enter your email address"
            onClick={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="password"
            placeholder="Enter your password"
            onClick={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button type="submit">Sign up</button>
        </form>
        <p>
          Have an account?{" "}
          <span
            onClick={() => {
              navigate("/");
            }}
          >
            Sign in
          </span>
        </p>
      </div>
    </div>
  );
}

export default Signup;
