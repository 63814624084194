import { FaHome } from "react-icons/fa";
import "./Header.css";
import { BiMenu, BiSearch } from "react-icons/bi";
import { BsTranslate } from "react-icons/bs";
import { useState } from "react";
import logo from "../../../assets/logo.png";

function Header() {
  const [menu, setMenu] = useState(false);

  return (
    <div className="header">
      <img className="header__logo" src={logo} alt="" />
      <ul className="header__ul">
        <li>
          <FaHome className="header__icons" /> Home
        </li>
        <li>
          <FaHome className="header__icons" /> Genre
        </li>
        <li>
          <FaHome className="header__icons" /> Ranking
        </li>
        <li>
          <FaHome className="header__icons" /> Create
        </li>
        <li>
          <FaHome className="header__icons" /> Shelf
        </li>
      </ul>
      <div className="header__end">
        <BiSearch className="header__icons" />
        <div>
          <BsTranslate />
          <p>English</p>
        </div>
      </div>
      <BiMenu
        className="header__menu"
        onClick={() => {
          setMenu(true);
        }}
      />
    </div>
  );
}

export default Header;
