import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./Home.css";
import home__hero__image from "../../../assets/home__hero__image.png";
import home__section__two__one from "../../../assets/home__section__two__one.png";
import image10 from "../../../assets/image10.png";
import image11 from "../../../assets/image11.png";
import image12 from "../../../assets/image12.png";
import { BsArrowRight } from "react-icons/bs";

function Home() {
  return (
    <div className="home">
      <div className="home__main">
        <section className="home__section__one">
          <Header />
          <div className="home__section__one__main">
            <div className="home__section__one__main__start">
              <button>RANK #13</button>
              <h1>ALMOST PERFECT PARTNERS</h1>
              <span>
                <h1>ROMANCE</h1>
                <h1>SHALLY POPY</h1>
              </span>
              <p>
                After recent divorcée Julie has sworn off romance to focus on
                her marketing career, her friends finally convince her to
                cautiously dip a toe back in by trying online dating. To Julie's
                surprise, she seems to hit it off with Blake, a charming
                architect. But over time, red flags appear revealing that Blake
                is still secretly obsessed with and harboring hopes to reunite
                with his ex, Lila
              </p>
              <button>READ NOW</button>
            </div>
            <div className="home__section__one__main__end">
              <img alt="" src={home__hero__image} />
            </div>
          </div>
        </section>
        <section className="home__section__two">
          <div className="home__section__two__start">
            <div className="home__section__two__start__block">
              <h1>Original tales</h1>
              <p>Your gateway to infinite stories</p>
            </div>
            <div className="home__section__two__start__block">
              <h1>Become an author</h1>
              <p>Earn as you create stories</p>
            </div>
            <div className="home__section__two__start__block">
              <h1>Author's manual</h1>
              <p>Step by step manual on how you can write top selling novels</p>
            </div>
          </div>
          <div className="home__section__two__end">
            <h1>Popular Novels</h1>
            <div className="home__section__two__end__section">
              <div className="home__section__two__end__one">
                <div>
                  <img alt="" src={home__section__two__one} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Romance</h3>
                    <h3>Shally Popy</h3>
                  </span>
                  <p>
                    ter recent divorcée Julie has sworn off romance to focus on
                    her marketing career, her friends finally convince her to
                    cautiously dip a toe back in by trying online dating. To
                    Julie's surprise, she seems to hit it off with Blake, a
                    charming architect. But over time, red flags appear
                    revealing that Blake is still secretly obsessed with and
                    harboring hopes to reunite with his ex, Lila.
                  </p>
                </div>
              </div>
              <div className="home__section__two__end__two">
                <div>
                  <img alt="" src={image10} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                </div>
                <div>
                  <img alt="" src={image10} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                </div>
                <div>
                  <img alt="" src={image10} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                </div>
                <div>
                  <img alt="" src={image10} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                </div>
                <div>
                  <img alt="" src={image10} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                </div>
                <div>
                  <img alt="" src={image10} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                </div>
                <div>
                  <img alt="" src={image10} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                </div>
                <div>
                  <img alt="" src={image10} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                </div>
                <div>
                  <img alt="" src={image10} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                </div>
                <div>
                  <img alt="" src={image10} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home__section__three">
          <div className="home__section__three__one">
            <h1>Top Ranked</h1>
            <img alt="" src={image11} />
          </div>
          <div className="home__section__three__two">
            <h1>Trending</h1>
            <img alt="" src={image12} />
          </div>
        </section>
        <section className="home__section__four">
          <span className="home__section__four__heading">
            <h1>Trending</h1>
            <h2>
              See all{" "}
              <BsArrowRight className="home__section__four__heading__icon" />
            </h2>
          </span>
          <div className="home__section__four__display">
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
          </div>
        </section>
        <section className="home__section__five">
          <section className="home__section__four__alt">
            <span className="home__section__four__heading">
              <h1>Top Romance</h1>
              <h2>
                See all{" "}
                <BsArrowRight className="home__section__four__heading__icon" />
              </h2>
            </span>
            <div className="home__section__four__alt__display">
              <div>
                <img alt="" src={image10} />
                <div>
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                  <h4>Romance</h4>
                </div>
              </div>
              <div>
                <img alt="" src={image10} />
                <div>
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                  <h4>Romance</h4>
                </div>
              </div>
              <div>
                <img alt="" src={image10} />
                <div>
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                  <h4>Romance</h4>
                </div>
              </div>
              <div>
                <img alt="" src={image10} />
                <div>
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                  <h4>Romance</h4>
                </div>
              </div>
              <div>
                <img alt="" src={image10} />
                <div>
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                  <h4>Romance</h4>
                </div>
              </div>
              <div>
                <img alt="" src={image10} />
                <div>
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                  <h4>Romance</h4>
                </div>
              </div>
              <div>
                <img alt="" src={image10} />
                <div>
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                  <h4>Romance</h4>
                </div>
              </div>
              <div>
                <img alt="" src={image10} />
                <div>
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Fantasy</h3>
                    <h4>11.4k</h4>
                  </span>
                  <h4>Romance</h4>
                </div>
              </div>
            </div>
          </section>
          <section className="home__section__four">
            <span className="home__section__four__heading">
              <h1>New Arrivals</h1>
              <h2>
                See all{" "}
                <BsArrowRight className="home__section__four__heading__icon" />
              </h2>
            </span>
            <div className="home__section__four__display">
              <div>
                <img alt="" src={image10} />
                <h1>Almost Perfect Parners</h1>
                <span>
                  <h3>Fantasy</h3>
                  <h4>11.4k</h4>
                </span>
              </div>
              <div>
                <img alt="" src={image10} />
                <h1>Almost Perfect Parners</h1>
                <span>
                  <h3>Fantasy</h3>
                  <h4>11.4k</h4>
                </span>
              </div>
              <div>
                <img alt="" src={image10} />
                <h1>Almost Perfect Parners</h1>
                <span>
                  <h3>Fantasy</h3>
                  <h4>11.4k</h4>
                </span>
              </div>
              <div>
                <img alt="" src={image10} />
                <h1>Almost Perfect Parners</h1>
                <span>
                  <h3>Fantasy</h3>
                  <h4>11.4k</h4>
                </span>
              </div>
              <div>
                <img alt="" src={image10} />
                <h1>Almost Perfect Parners</h1>
                <span>
                  <h3>Fantasy</h3>
                  <h4>11.4k</h4>
                </span>
              </div>
              <div>
                <img alt="" src={image10} />
                <h1>Almost Perfect Parners</h1>
                <span>
                  <h3>Fantasy</h3>
                  <h4>11.4k</h4>
                </span>
              </div>
              <div>
                <img alt="" src={image10} />
                <h1>Almost Perfect Parners</h1>
                <span>
                  <h3>Fantasy</h3>
                  <h4>11.4k</h4>
                </span>
              </div>
              <div>
                <img alt="" src={image10} />
                <h1>Almost Perfect Parners</h1>
                <span>
                  <h3>Fantasy</h3>
                  <h4>11.4k</h4>
                </span>
              </div>
            </div>
          </section>
        </section>
        <section className="home__section__four">
          <span className="home__section__four__heading">
            <h1>Completed Novels</h1>
            <h2>
              See all{" "}
              <BsArrowRight className="home__section__four__heading__icon" />
            </h2>
          </span>
          <div className="home__section__four__display">
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
            <div>
              <img alt="" src={image10} />
              <h1>Almost Perfect Parners</h1>
              <span>
                <h3>Fantasy</h3>
                <h4>11.4k</h4>
              </span>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
