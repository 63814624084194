import "./Login.css";
import logo from "../../assets/logo.png";
import google__icon from "../../assets/google.png";
import facebook__icon from "../../assets/facebook.png";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      email,
      password,
    });
    navigate("/home");
  };
  return (
    <div className="login">
      <div className="login__container">
        <img src={logo} alt="" />
        <h1>You are welcome</h1>
        <div className="login__container__social__signin">
          <button>
            <img src={google__icon} alt="" />
            Sign in with Google
          </button>
          <button>
            <img src={facebook__icon} alt="" />
            Sign in with Facebook
          </button>
        </div>

        <form className="login__container__form" onSubmit={handleSubmit}>
          <input
            type="email"
            important
            placeholder="Enter your email address"
            onClick={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="password"
            placeholder="Enter your password"
            onClick={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button type="submit">Sign In</button>
          <p>
            Forgot password? <span>Reset Here</span>
          </p>
        </form>
        <p>
          Don't have an account?{" "}
          <span onClick={() => navigate("/signup")}>Sign up</span>
        </p>
      </div>
    </div>
  );
}

export default Login;
